import styled, {css} from 'styled-components';

import {bottomDashedLineCss} from '~components/atoms/DashedLine/styles';
import {Text, textCss} from '~components/atoms/Text';
import breakpoints from '~constants/breakpoints';

export const MaskedLogo = styled.div`
    mask: url('/assets/images/logo/logo.svg');
    mask-repeat: no-repeat;
    mask-position: 16px 0;
    width: 100%;
    height: 16px;
    box-sizing: content-box;
    z-index: 2;
    position: absolute;
    top: 20px;
    background: linear-gradient(
        to right,
        black 0%,
        black 50%,
        white 50%,
        white 100%
    );
    background-size: 200%;
    background-position-x: 100%;
    transition: background-position var(--transition);
    cursor: pointer;
    pointer-events: all;

    ${breakpoints.abovePhone} {
        mask-position: 24px 0;
    }
`;
export const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const FixedRow = styled.div`
    top: 0;
    position: sticky;
    background: white;
    z-index: 15;
`;

export const ScrollWrapper = styled.div`
    overflow-y: auto;
    max-height: 100%;
    padding: 0 24px 60px;
`;

export const ContentWrapper = styled.div`
    padding-top: 60px;
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.05);
    transform: translateX(-100%);
    transition: transform var(--transition);
    pointer-events: all;
`;

const TopRowBtn = styled.button`
    background: none;
    padding: 0;
    appearance: none;
    border: none;
    color: black;
    display: flex;
    cursor: pointer;

    &:disabled {
        cursor: default;
    }
`;

export const CloseBtn = styled(TopRowBtn)`
    text-transform: uppercase;

    svg {
        margin-right: 10px;
        transform: translateY(1px);
    }
`;

const openStateCss = css`
    ${ContentWrapper} {
        transform: translateX(0);
    }

    ${MaskedLogo} {
        background-position-x: 0;
    }
`;

type ContainerProps = {
    isOpen: boolean | null;
    alwaysOpenOnDesktop: boolean;
};

export const filtersMenuWidth = 332;

export const Container = styled.div<ContainerProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    width: ${filtersMenuWidth}px;
    max-width: 100vw;
    z-index: 10;
    pointer-events: none;

    // prevent transition on load
    --transition: linear 0;
    ${({isOpen}) =>
        isOpen === false &&
        css`
            --transition: ease-in-out 500ms;
        `}

    ${({isOpen}) =>
        isOpen &&
        css`
            --transition: ease-in-out 1000ms;

            ${openStateCss};
        `}

  ${({alwaysOpenOnDesktop}) =>
        alwaysOpenOnDesktop &&
        css`
            ${OpenBtn} {
                backdrop-filter: none;
            }

            ${breakpoints.abovePhone} {
                ${openStateCss}
                ${CloseBtn} {
                    pointer-events: none;

                    svg {
                        display: none;
                    }
                }
            }

            ${breakpoints.phone} {
                ${MaskedLogo} {
                    display: none;
                }

                ${ContentWrapper} {
                    padding-top: 20px;
                }
            }
        `}
`;

type ContainerUnderLogoProps = {
    color: 'white' | 'black';
    isList?: boolean;
};

export const ContainerUnderLogo = styled.div<ContainerUnderLogoProps>`
    position: absolute;
    display: flex;
    align-items: center;
    color: ${({color}) => color};

    ${breakpoints.abovePhone} {
        left: 24px;
        top: 60px;
    }

    ${breakpoints.phone} {
        left: 20px;
        top: ${({isList}) => (isList ? 6 : 52)}px;
    }
`;

export const PageTitle = styled(Text)`
    font-weight: 450;

    svg {
        margin-right: 8px;
        transform: translateY(3px);
    }
`;

export const OpenBtn = styled.button`
    background: none;
    appearance: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
    color: inherit;

    svg {
        margin-right: 8px;
        transform: translateY(-1px);
    }

    ${breakpoints.abovePhone} {
        border: none;
        padding: 0;
    }

    ${breakpoints.phone} {
        transform: translateX(-21px);
        padding: 12px 10px 11px 16px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        backdrop-filter: blur(4px);
        border: 0.6px solid #ffffff;
        border-radius: 0 12px 12px 0;

        > * {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.06em;
        }
    }
`;

export const ClearFiltersBtn = styled(TopRowBtn)`
    svg {
        margin-right: 8px;
        transform: translateY(1px);
    }
`;

export const Section = styled.div`
    width: 100%;
    padding: 28px 16px;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        ${bottomDashedLineCss}
    }
`;

export const SectionName = styled(Text).attrs({
    as: 'h5',
})`
    margin: 0 0 6px 0;
`;

type PartnerProps = {
    selected: boolean;
};

export const Partner = styled.button<PartnerProps>`
    background: none;
    border: none;
    margin: 6px 0;
    padding: 0;
    max-width: max-content;
    text-align: left;
    cursor: pointer;
    ${textCss}

    ${({selected}) =>
        selected &&
        css`
            font-weight: 600;
        `}
`;
