import dynamic from 'next/dynamic';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import {Modal, ModalProps} from '~components/atoms/Modal';
import {Portals} from '~constants/portals';

const ConnectWalletButtons = dynamic(() => import('~atoms/ConnectWalletButtons'), {
    ssr: false,
});

type ConnectWalletModalProps = Pick<ModalProps, 'isOpen' | 'close'>;
const ConnectWalletModal = ({...props}: ConnectWalletModalProps) => {
    const t = useScopeTranslation('common');

    return (
        <Modal
            rootId={Portals.CONNECT_WALLET}
            width={416}
            height={287}
            title={t('connectWallet')}
            {...props}
        >
            <ConnectWalletButtons />
        </Modal>
    );
};

export default ConnectWalletModal;
