export default function getErrorMessage(message: string | any) {
    if (message.includes('"message":')) {
        let messageJson = JSON.parse(message);
        if (messageJson?.message) {
            return messageJson.message;
        }
    }

    switch (message) {
        case 'execution reverted: no-tokens-left-to-buy':
            return 'You bought max amount of tokens';
        case 'execution reverted: too-little-tokens-holded':
            return "It appears you've sold HERO during the holding period, which has removed your address from the whitelist.";
        case 'execution reverted: no-funds-end':
            return 'You cannot send transaction without BNB';
        case 'execution reverted: Vesting started':
            return 'Presale finished, because vesting has already started';
        case 'execution reverted: ico-ended':
            return 'Presale finished, because vesting has already started';
        case 'execution reverted: BEP20: transfer amount exceeds allowance':
            return 'You cannot stake without approval';
        case 'execution reverted: already-minted':
            return 'Land NFT is already minted';
        case 'execution reverted: already claimed':
            return 'Already claimed';
        case 'execution reverted: not-started':
            return 'Auction not started.';
        case 'execution reverted: already-sold':
            return 'Plot already sold.';
        case 'execution reverted: proof-incorrect':
            return 'Proof is incorrect.';
        case 'execution reverted: not-owner':
            return 'You are not owner';
        case 'execution reverted: too-late':
            return 'Claim cashback ended';
        case 'transaction failed':
            return 'Transaction failed';
        case 'MetaMask Tx Signature: User denied transaction signature.':
            return 'User denied transaction signature';
        default:
            return `Something went wrong: "${message}"`;
    }
}
