import Link from 'next/link';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import ArrowLeftIcon from '~assets/images/icons/ui/arrow-left.svg';
import PlotIcon from '~assets/images/icons/ui/plot.svg';
import {Routes, ScreenTypes} from '~constants/routes';

import {HowItWorksLink, LinkText, RoundInfoContainer} from './styles';

export const Navigation = () => {
    const t = useScopeTranslation('common', 'roundInfo');

    return (
        <RoundInfoContainer>
            <strong>{'·'}</strong>
            <span>{t('finished')}</span>
            <strong>{'·'}</strong>
            <Link
                href={{
                    pathname: Routes.MyPlots,
                    query: {
                        slug: [ScreenTypes.List],
                    },
                }}
                passHref
            >
                <LinkText>
                    <PlotIcon />
                    {t('mintNFT')}
                </LinkText>
            </Link>
            <strong>{'·'}</strong>
            <Link passHref href={Routes.LandSaleInfo}>
                <HowItWorksLink data-cy="how-it-works-button">
                    {t('howItWorks')}
                    <ArrowLeftIcon />
                </HowItWorksLink>
            </Link>
        </RoundInfoContainer>
    );
};
