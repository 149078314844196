import {
    QueryParamsType,
    safeJsonParse,
    parseApiError,
} from '@uigstudio/uig-nextjs-helper';

import {TokenType} from '~types/Auth';

export type FetchRequestInit = RequestInit & {
    queryParams?: QueryParamsType;
    bindParams?: QueryParamsType;
    data?: Record<string, unknown>;
    baseUrl?: string;
    withContentType?: boolean;
    withJwtToken?: boolean;
};

export type FetchHeaders = Pick<
    FetchRequestInit,
    'withJwtToken' | 'withContentType' | 'headers'
> & {
    jwtToken?: TokenType | undefined;
};

export const fetchHeaders = ({
    withJwtToken,
    jwtToken,
    withContentType,
    headers,
}: FetchHeaders) => {
    const authorizationHeaders = {
        ...(jwtToken && withJwtToken && {Authorization: `Bearer ${jwtToken.token}`}),
    };

    return {
        headers: {
            ...(withContentType ? {'Content-Type': 'application/json'} : {}),
            ...authorizationHeaders,
            ...headers,
        },
    };
};

export const fetchBody = ({body, data}: Pick<FetchRequestInit, 'body' | 'data'>) => {
    const parsedBody = data ? JSON.stringify(data) : body ? body : undefined;
    return parsedBody ? {body: parsedBody} : {};
};

export const resolveResponse = <T>(fetchResponse: Promise<Response>) => {
    return fetchResponse.then(
        (response) =>
            new Promise<T>((resolve, reject) => {
                response.text().then((data) => {
                    try {
                        const responseData = safeJsonParse(data, true);

                        if (response.ok) {
                            resolve(responseData);
                        } else {
                            reject(parseApiError(responseData));
                        }
                    } catch (e) {
                        reject(parseApiError(e));
                    }
                });
            }),
    );
};
