import styled from 'styled-components';

import {Text} from '~atoms/Text';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`;

export const Description = styled.div`
    opacity: 0.5;

    p {
        display: block;
        margin-bottom: 32px;
        margin-top: 0;
    }

    ${Text} {
        display: block;
    }
`;
