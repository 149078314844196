import styled from 'styled-components';

type DropdownProps = {
    top: number;
};

export const Dropdown = styled.div<DropdownProps>`
    padding: 8px 8px 16px;
    background: #ffffff;
    border: 1px solid hsl(0, 0%, 90%);
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    position: absolute;
    display: none;
    top: calc(100% + ${({top}) => top}px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    color: black;
    cursor: default;

    &:hover {
        display: block;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        height: ${({top}) => top + 1}px;
        z-index: 11;
    }
`;

export const DropdownItem = styled.div`
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    background: transparent;
    transition: background-color ease-in-out 300ms;

    &:hover,
    &:focus {
        background: rgba(0, 0, 0, 0.03);
    }
`;
