import {useEffect, useState} from 'react';
import Link from 'next/link';
import {shortenAddress, useEthers} from '@usedapp/core';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import LogoutIcon from '~assets/images/icons/ui/logout.svg';
import Button from '~atoms/Button';
import {Avatar} from '~atoms/Avatar/styles';
import ConnectWalletModal from '~molecules/ConnectWalletModal';
import {Dropdown, DropdownItem} from '~components/atoms/Dropdown/styles';
import {Routes, ScreenTypes} from '~constants/routes';
import useDisconnectWallet from '~atoms/ConnectWalletButtons/useDisconnectWallet';

import {
    Container,
    DisconnectWalletBtn,
    WalletAddress,
    WalletContainer,
    WalletWrapper,
} from './styles';

type DesktopWalletMenuProps = {
    color: 'white' | 'black';
    absolute?: boolean;
};

export const DesktopWalletMenu = ({
    color,
    absolute = true,
}: DesktopWalletMenuProps) => {
    const logOut = useDisconnectWallet();
    const t = useScopeTranslation('common');
    // const [soundToggle, setSoundToggle] = useState(false);
    const [connectWalletModalToggle, setConnectWalletModalToggle] = useState(false);
    const {account} = useEthers();

    useEffect(() => {
        if (account) setConnectWalletModalToggle(false);
    }, [account]);

    return (
        <Container color={color} absolute={absolute}>
            {/* <SpeakerBtn onClick={() => setSoundToggle((current) => !current)}>
                {soundToggle ? <SpeakerIcon /> : <SpeakerMutedIcon />}
            </SpeakerBtn> */}
            {account ? (
                <WalletWrapper>
                    <Link href={Routes.MyProfile} passHref>
                        <WalletContainer>
                            <WalletAddress>{shortenAddress(account)}</WalletAddress>
                            <Avatar size={24} />
                        </WalletContainer>
                    </Link>
                    <Dropdown top={8}>
                        <Link href={Routes.MyProfile} passHref>
                            <DropdownItem as="a">
                                {t('menuLinks.profile')}
                            </DropdownItem>
                        </Link>
                        <Link
                            href={{
                                pathname: Routes.MyPlots,
                                query: {
                                    slug: [ScreenTypes.List],
                                },
                            }}
                            passHref
                        >
                            <DropdownItem as="a">
                                {t('menuLinks.yourPlots')}
                            </DropdownItem>
                        </Link>
                        <DisconnectWalletBtn onClick={logOut}>
                            <LogoutIcon />
                            {t('disconnectWallet')}
                        </DisconnectWalletBtn>
                    </Dropdown>
                </WalletWrapper>
            ) : (
                <Button
                    variant={color === 'white' ? 'transparent' : 'primary'}
                    onClick={() => setConnectWalletModalToggle(true)}
                >
                    {t('connectWallet')}
                </Button>
            )}
            <ConnectWalletModal
                isOpen={connectWalletModalToggle}
                close={() => setConnectWalletModalToggle(false)}
            />
        </Container>
    );
};
