import React, {ReactNode} from 'react';

type IfProps = {
    condition: boolean | unknown;
    children: ReactNode;
};

export const If: React.FC<IfProps> = ({condition, children}) => {
    return condition ? <>{children}</> : null;
};
