import React, {ReactElement} from 'react';

import {Loader} from '~atoms/Loader';

import {LoaderWrapper, StylesButton, Icon, StylesButtonProps} from './styles';

type ButtonProps = StylesButtonProps &
    React.ComponentProps<typeof StylesButton> & {
        icon?: ReactElement;
    };
const Button = React.forwardRef<typeof StylesButton, ButtonProps>(
    ({children, loading, icon, ...props}, ref) => {
        return (
            <StylesButton {...props} $loading={Boolean(loading)} ref={ref}>
                {loading && (
                    <LoaderWrapper>
                        <Loader size={20} color={'#fff'} bg={'#000'} />
                    </LoaderWrapper>
                )}
                {icon && <Icon>{icon}</Icon>}
                {children}
            </StylesButton>
        );
    },
);

Button.displayName = 'Button';

export default Button;
