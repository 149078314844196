import styled from 'styled-components';

import {Avatar} from '~components/atoms/Avatar/styles';
import {Text, textCss} from '~components/atoms/Text';

export const UserSectionContainer = styled.div`
    background: var(--gray-bg);
    border-radius: 24px;
    padding: 38px 27px;
    display: flex;
    flex-direction: column;
    color: black;
`;

export const TopRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 27px;
`;

export const BorderAvatar = styled(Avatar)`
    border: 1px solid black;
    position: relative;

    &::after {
        content: '';
        top: 50%;
        left: 50%;
        --size: 24px;
        width: var(--size);
        height: var(--size);
        border: 1px dashed black;
    }
`;

export const WalletAddress = styled(Text)`
    opacity: 0.5;
    margin: 0 13px 0 17px;
`;

export const LinksContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
`;

export const DisconnectWalletBtn = styled.button`
    ${textCss};
    background: none;
    margin: 0;
    padding: 0;
    border: 0;
    appearance: none;
    color: var(--red);
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;

    & > *:first-child {
        margin-right: 5px;
        transform: translateY(-2px);
    }
`;
