import styled from 'styled-components';

export const LoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
