import {useCallback} from 'react';
import {useEthers} from '@usedapp/core';

import {useJwtToken} from '~providers/AuthProvider';

export default function useDisconnectWallet() {
    const {deactivate} = useEthers();
    const {removeToken} = useJwtToken();

    return useCallback(() => {
        removeToken();
        deactivate();
        localStorage.removeItem('walletconnect');
    }, [deactivate]);
}
