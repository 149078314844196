import {css} from 'styled-components';

export const bottomDashedLineCss = css`
    --dash-color: hsl(0, 0%, 70%);
    background: linear-gradient(
        to right,
        transparent 0%,
        transparent 80%,
        var(--dash-color) 80%,
        var(--dash-color) 100%
    );
    background-repeat: repeat-x;
    background-size: 5px 1px;
    background-position-y: bottom;
`;

export const topDashedLineCss = css`
    --dash-color: hsl(0, 0%, 70%);
    background: linear-gradient(
        to right,
        transparent 0%,
        transparent 80%,
        var(--dash-color) 80%,
        var(--dash-color) 100%
    );
    background-repeat: repeat-x;
    background-size: 5px 1px;
    background-position-y: top;
`;

export const rightDashedLineCss = css`
    --dash-color: hsl(0, 0%, 70%);
    background: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 80%,
        var(--dash-color) 80%,
        var(--dash-color) 100%
    );

    background-repeat: repeat-y;
    background-size: 1px 5px;
    background-position-x: right;
`;
