import React from 'react';
import {useRouter} from 'next/router';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import {Modal, ModalProps} from '~components/atoms/Modal';
import {Portals} from '~constants/portals';
import {H5, Text} from '~atoms/Text';
import ErrorIcon from '~assets/images/icons/ui/error.svg';
import Button from '~atoms/Button';
import {Routes} from '~constants/routes';

import {Buttons, Container, Content, Description} from './styles';

type NotificationModalProps = Pick<ModalProps, 'isOpen' | 'close'> & {
    plotNames: string[] | undefined;
};
export const NotificationModal = ({
    close,
    plotNames,
    ...props
}: NotificationModalProps) => {
    const t = useScopeTranslation('common', 'removedNotification');
    const router = useRouter();
    return (
        <Modal
            rootId={Portals.NOTIFICATION}
            width={478}
            height={409}
            close={close}
            {...props}
        >
            <Container>
                <Content>
                    <ErrorIcon />
                    <H5>{t('title')}</H5>
                    <Description>
                        <Text>{t('description1')}</Text>
                        {plotNames && (
                            <p>
                                {plotNames.join(', ')}
                                {'.'}
                            </p>
                        )}
                        <Text
                            dangerouslySetInnerHTML={{__html: t('description2')}}
                        />
                    </Description>
                </Content>
                <Buttons>
                    <Button
                        onClick={async () => {
                            close && close();
                            await router.push(Routes.MyProfile);
                        }}
                    >
                        {t('btnLabel')}
                    </Button>
                </Buttons>
            </Container>
        </Modal>
    );
};
