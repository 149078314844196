import React, {useMemo} from 'react';
import Head from 'next/head';
import {useTranslation} from 'react-i18next';

import {If} from '~atoms/If';
import defaultPoster from '~assets/images/poster/poster.png';
import env from '~utils/env';

type HTMLHeadProps = {
    title?: string;
    description?: string;
};

export const HTMLHead: React.FC<HTMLHeadProps> = ({title, description}) => {
    const {t} = useTranslation('common');

    const {pageTitle, pageDescription, pagePoster} = useMemo(() => {
        return {
            pageTitle: [t('appName'), title].filter((v) => v).join(' · '),
            pageDescription: description || t('description'),
            pagePoster: [env('HOST')?.replace(/\/$/, ''), defaultPoster.src].join(
                '',
            ),
        };
    }, [title, description]);

    return (
        <Head>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>

            <link rel="shortcut icon" href="/favicon.svg" type="image/x-icon" />

            <meta name="referrer" content="origin" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <If condition={pageDescription}>
                <meta name="description" content={pageDescription} />
                <meta property="og:description" content={pageDescription} />
            </If>

            <meta property="og:title" content={pageTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={pagePoster} />

            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="black-translucent"
            />
            <meta name="theme-color" content="#000000" />
            <If condition={process.env.NEXT_PUBLIC_GTM_ID}>
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
                    `,
                    }}
                />
            </If>
        </Head>
    );
};
