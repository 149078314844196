import styled, {css} from 'styled-components';

import {bottomDashedLineCss} from '~components/atoms/DashedLine/styles';
import breakpoints from '~constants/breakpoints';
import {MaskedLogo} from '~molecules/FiltersMenu/styles';

type ContentWrapperProps = {
    isOpen: boolean | null;
    color: 'black' | 'white' | undefined;
};
export const ContentWrapper = styled.div<ContentWrapperProps>`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    box-shadow: -4px 0 30px rgba(0, 0, 0, 0.07);
    transform: translateX(100%);
    transition: transform var(--transition);
    overflow-y: auto;
    z-index: 20;
    padding: 60px 16px 40px;
    ${({color}) =>
        color === 'black'
            ? css`
                  color: white;
                  background: black;
              `
            : css`
                  color: black;
                  background: white;
              `}
    ${({isOpen}) =>
        isOpen &&
        css`
            transform: translateX(0);

            ${MaskedLogo} {
                background-position-x: 0;
            }
        `}
`;

export const TopRow = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 16px 16px;
`;

type TopBarProps = {
    background: boolean;
    isOpen: boolean | null;
    color: 'white' | 'black' | undefined;
};

export const TopBar = styled.div<TopBarProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px;
    width: 100%;
    background-size: 200% 100%;
    z-index: 22;
    pointer-events: none;
    transition: box-shadow ease-in-out 300ms 500ms;

    ${({background}) =>
        background
            ? css`
                  background-position-x: 100%;
                  color: black;
                  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.07);
              `
            : css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  color: white;
                  background-position-x: 0;
              `};

    ${({color}) =>
        color === 'black'
            ? css`
                  color: white;
                  background-color: black;
              `
            : color === 'white'
            ? css`
                  background: linear-gradient(
                      to right,
                      transparent 0%,
                      transparent 50%,
                      white 50%,
                      white 100%
                  );
              `
            : css``}
    ${({isOpen}) =>
        isOpen &&
        css`
            transition-delay: 50ms;
            color: black;
            box-shadow: none;
        `}
  &,
  & + ${ContentWrapper} {
        // prevent transition on load
        ${({isOpen}) =>
            isOpen === false &&
            css`
                --transition: ease-in-out 500ms;
            `}

        --transition: ease-in-out 1000ms;

        ${breakpoints.abovePhoneS} {
            ${({background}) =>
                !background &&
                css`
                    width: 375px;

                    ${TopRow} {
                        display: none;
                    }
                `}
        }

        ${breakpoints.aboveTablet} {
            display: none;
        }
    }
`;

export const ToggleButton = styled.button`
    background: none;
    appearance: none;
    margin: 0 0 0 32px;
    padding: 0;
    border: none;
    cursor: pointer;
    height: 24px;
    display: flex;
    align-items: center;
    color: inherit;
    pointer-events: all;
`;

export const InfoContainer = styled.div`
    padding: 0 0 40px;
    width: 100%;
    margin-bottom: 40px;
    ${bottomDashedLineCss};
    --dash-color: black;
`;
