import Link from 'next/link';
import {shortenAddress} from '@usedapp/core';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import {Text} from '~components/atoms/Text';
import LogoutIcon from '~assets/images/icons/ui/logout.svg';
import {Routes, ScreenTypes} from '~constants/routes';

import {
    BorderAvatar,
    DisconnectWalletBtn,
    LinksContainer,
    TopRow,
    UserSectionContainer,
    WalletAddress,
} from './styles';

type UserSectionProps = {
    user: string;
    disconnectWallet: () => void;
};

export const UserSection = ({user, disconnectWallet}: UserSectionProps) => {
    const t = useScopeTranslation('common');

    return (
        <UserSectionContainer>
            <TopRow>
                <BorderAvatar size={24} />
                <WalletAddress>{shortenAddress(user)}</WalletAddress>
            </TopRow>
            <LinksContainer>
                <Link href={Routes.MyProfile} passHref>
                    <Text as="a">{t('menuLinks.profile')}</Text>
                </Link>
                <Link
                    href={{
                        pathname: Routes.MyPlots,
                        query: {
                            slug: [ScreenTypes.List],
                        },
                    }}
                    passHref
                >
                    <Text as="a">{t('menuLinks.yourPlots')}</Text>
                </Link>
                <DisconnectWalletBtn onClick={disconnectWallet}>
                    <LogoutIcon />
                    {t('disconnectWallet')}
                </DisconnectWalletBtn>
            </LinksContainer>
        </UserSectionContainer>
    );
};
