import React, {ComponentProps} from 'react';

import {Banner} from '~components/molecules/Banner';
import {DesktopTopBar} from '~components/molecules/DesktopTopBar';
import Footer from '~components/molecules/Footer';
import {
    HamburgerMenu,
    HamburgerMenuProps,
} from '~components/molecules/HamburgerMenu';
import {Portals} from '~constants/portals';
import SecuredPage from '~molecules/SecuredPage';
import {If} from '~components/atoms/If';
import Notification from '~molecules/Notification';
import useErrors from '~hooks/useErrors';
import {SwitchNetworkModal} from '~molecules/SwitchNetworkModal';
import {mintChainId} from '~constants/chainId';

import {HTMLHead} from './HTMLHead';
import {Container, Content, ContentProps} from './styles';

export type LayoutProps = {
    menuProps?: HamburgerMenuProps;
    showDesktopTopBar?: boolean;
    hideBanner?: boolean;
    children?: React.ReactNode;
    showFooter?: boolean;
    headProps?: ComponentProps<typeof HTMLHead>;
    contentProps?: ContentProps;
    securedPage?: boolean;
    forAdmin?: boolean;
    autoLogin?: boolean;
    hideHamburger?: boolean;
    chainId?: number;
};

const Layout: React.FC<LayoutProps> = ({
    children,
    menuProps,
    showDesktopTopBar,
    hideBanner,
    hideHamburger,
    showFooter,
    headProps,
    contentProps,
    securedPage = false,
    forAdmin = false,
    chainId = mintChainId,
    ...props
}) => {
    useErrors();

    return (
        <>
            <HTMLHead {...headProps} />
            <Container {...props}>
                <div id={Portals.SWITCH_NETWORK} />
                <div id={Portals.CONNECT_WALLET} />
                <div id={Portals.NOTIFICATION} />

                <If condition={!hideBanner}>
                    <Banner />
                </If>
                <If condition={!hideHamburger}>
                    <HamburgerMenu {...menuProps} />
                </If>
                <If condition={showDesktopTopBar}>
                    <DesktopTopBar />
                </If>
                <Content {...contentProps}>
                    <SecuredPage isSecure={securedPage} forAdmin={forAdmin}>
                        {children}
                    </SecuredPage>
                    {showFooter && <Footer />}
                </Content>
                <SwitchNetworkModal chainId={chainId} />
                <Notification />
            </Container>
        </>
    );
};

export default Layout;
