import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import Logo from '~assets/images/logo/everdome-logo-footer.svg';
import FooterButton from '~components/atoms/FooterButton';
import FooterNavItem from '~components/atoms/FooterNavItem';
import TelegramIcon from '~assets/images/icons/ui/telegram-icon.svg';
import TelegramNewsIcon from '~assets/images/icons/ui/telegram-news-icon.svg';
import LinkedinIcon from '~assets/images/icons/ui/linkedin-icon.svg';
import YoutubeIcon from '~assets/images/icons/ui/youtube-icon.svg';
import InstagramIcon from '~assets/images/icons/ui/instagram-icon.svg';
import TwitterIcon from '~assets/images/icons/ui/twitter-icon.svg';
import DiscordIcon from '~assets/images/icons/ui/discord.svg';
import {Links} from '~constants/links';
import {Routes} from '~constants/routes';

import {
    FooterButtonsContainer,
    FooterContainer,
    FooterNavContainer,
    StyledLink,
    TermsAndPrivacyContainer,
    RightReserved,
} from './styles';

const Footer = () => {
    const t = useScopeTranslation('common', 'footer');

    return (
        <FooterContainer>
            <Logo />
            <FooterNavContainer>
                <FooterNavItem text={t('everdome')} href={Links.everdome} />
                <FooterNavItem text={t('contact')} href={Links.contact} />
                <FooterNavItem
                    text={t('landsale')}
                    href={Routes.LandSaleInfo}
                    lastItem
                />
            </FooterNavContainer>
            <FooterButtonsContainer>
                <FooterButton href={Links.discord} icon={<DiscordIcon />} />
                <FooterButton href={Links.telegram} icon={<TelegramIcon />} />
                <FooterButton
                    href={Links.telegramNews}
                    icon={<TelegramNewsIcon />}
                />
                <FooterButton href={Links.linkedIn} icon={<LinkedinIcon />} />
                <FooterButton href={Links.yt} icon={<YoutubeIcon />} />
                <FooterButton href={Links.insta} icon={<InstagramIcon />} />
                <FooterButton href={Links.twitter} icon={<TwitterIcon />} />
            </FooterButtonsContainer>
            <TermsAndPrivacyContainer>
                <StyledLink href={Links.terms}>{t('terms')}</StyledLink>
                <RightReserved>{t('rightReserved')}</RightReserved>
                <StyledLink href={Links.privacy}>{t('privacy')}</StyledLink>
            </TermsAndPrivacyContainer>
        </FooterContainer>
    );
};

export default Footer;
