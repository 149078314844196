import {useCallback, useEffect, useMemo} from 'react';
import {useEthers} from '@usedapp/core';

import {useJwtTokenStatus, useParsedJwtToken} from '~providers/AuthProvider';
import {JwtRole} from '~types/Auth';
import useLogin from '~molecules/SecuredPage/useLogin';

export default function useSecuredPage(isSecure: boolean, forAdmin: boolean) {
    const {refreshTokenValid, accessTokenValid} = useJwtTokenStatus();
    const {renewRefreshToken, renewAccessToken} = useLogin();
    const parsedJwtToken = useParsedJwtToken();
    const {account} = useEthers();

    const isAdmin = useMemo(
        () => (parsedJwtToken?.groups || []).includes(JwtRole.Admin),
        [parsedJwtToken],
    );

    const checkAuth = useCallback(async () => {
        if (accessTokenValid || !account) {
            return;
        }
        if (refreshTokenValid) {
            await renewAccessToken();
            return;
        }
        await renewRefreshToken();
    }, [
        account,
        accessTokenValid,
        refreshTokenValid,
        renewAccessToken,
        renewRefreshToken,
    ]);

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    const hasAccess = useMemo(() => {
        if (!isSecure) {
            return true;
        }
        let authValid = Boolean(account);
        authValid &&= accessTokenValid && refreshTokenValid;
        if (forAdmin) {
            authValid &&= isAdmin;
        }
        return authValid;
    }, [isSecure, forAdmin, isAdmin, account, accessTokenValid, refreshTokenValid]);

    const loading = useMemo(
        () =>
            account && isSecure
                ? !Boolean(refreshTokenValid && accessTokenValid)
                : false,
        [isSecure, account, refreshTokenValid, accessTokenValid],
    );

    return useMemo(
        () => ({
            account,
            isAdmin,
            hasAccess,
            loading,
        }),
        [account, isAdmin, hasAccess, loading],
    );
}
