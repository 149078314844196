export enum ApiEndpoints {
    Plots = '/maps/plots/list',
    PlotsImages = '/maps/plots/images',
    PlotsImagesId = '/maps/plots/images/:plotId',
    PlotsReservation = '/maps/plots/reservations',
    PlotsPremium = '/maps/plots/premium',
    PlotsIds = '/plot-market/map/plots',
    PlotsList = '/plot-market/plots',
    MyPlotsList = '/plot-market/profile/my-plots',
    MyPlotsId = '/plot-market/profile/my-plots/:id',
    MyPlotsIds = '/plot-market/profile/my-plots/ids',
    Partners = 'plot-market/map/partners',
    PlotsSuggestions = '/plot-market/map/suggestions',
    PlotDetails = '/plot-market/plots/:id',
    PlotProof = '/plot-market/plots/:id/proof',
    PlotsNearby = '/plot-market/plots/:id/nearby-plots',
    PlotBids = '/plot-market/plots/:id/bids',
    Tickets = '/maps/plots/tickets/',
    TicketsId = '/maps/plots/tickets/:uuid',
    UnseenChanges = '/plot-market/profile/my-plots/unseen-changes',
    CashbackProofs = '/plot-market/cashback/:address/proof',
    MapGenerator = '/map-background-job',
}

export enum IdmApiEndpoints {
    Nonce = '/v1/auth/nonce',
    Login = '/v1/auth/login',
    RefreshToken = '/v1/auth/refresh-token',
    Me = '/v1/auth/me',
    GameCode = '/v1/game/code',
    GameVerify = '/v1/game/verify',
    GameToken = '/v1/game/token',
    Tickets = '/v1/profile/tickets/all',
    TicketsVerify = '/v1/profile/tickets/verify',
}
