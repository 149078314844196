import {useQuery} from 'react-query';

import useFetch from '~api/useFetch';
import env from '~utils/env';
import {IdmApiEndpoints} from '~constants/api';
import {AuthNonceResponse} from '~types/Auth';

export default function useFetchNonce(walletAddress?: string | null) {
    const {apiGet} = useFetch();

    return useQuery<AuthNonceResponse>(
        IdmApiEndpoints.Nonce,
        () =>
            apiGet(IdmApiEndpoints.Nonce, {
                baseUrl: env('IDM_API'),
                queryParams: {
                    walletAddress,
                },
            }),
        {enabled: false},
    );
}
