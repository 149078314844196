import {ProfileType} from '~types/Profile';

export type TokenType = {
    token: string;
    expiresIn: number;
};

export enum JwtRole {
    Admin = 'super-admin',
}

export type ParsedTokenData = {
    scope: string;
    sub: string;
    groups: JwtRole[];
    iat: number;
    exp: number;
    iss: string;
};

export type AuthNonceResponse = {
    message: string;
};

// /auth/login
export type AuthLoginData = {
    walletAddress: string;
    signature: string;
};

export type AuthLoginResponse = {accessToken: TokenType; refreshToken: TokenType};

// /auth/login
export type AuthMeData = Partial<ProfileType>;

export type AuthMeResponse = ProfileType;
