import React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';

import useFetchUnseenChanges, {
    usePutUnseenChanges,
} from '~api/useFetchUnseenChanges';
import {NotificationModal} from '~molecules/NotificationModal';

const Notification = () => {
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const {data, refetch} = useFetchUnseenChanges();
    const {mutateAsync} = usePutUnseenChanges();
    const close = useCallback(async () => {
        if (data && data.length > 0) {
            await mutateAsync({uuids: data.map((item) => item.uuid)});
            await refetch();
        }
    }, [data, mutateAsync, refetch]);

    useEffect(() => {
        if (data && data.length > 0) {
            setShowNotificationModal(true);
        } else {
            setShowNotificationModal(false);
        }
    }, [data]);

    const plotNames = useMemo(() => data?.map((item) => item.plotName), [data]);

    return (
        <NotificationModal
            isOpen={showNotificationModal}
            close={close}
            plotNames={plotNames}
        />
    );
};

export default Notification;
