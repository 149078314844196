type FooterButtonProps = {
    href: string;
    icon: JSX.Element;
};

const FooterButton = ({href, icon}: FooterButtonProps) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {icon}
        </a>
    );
};

export default FooterButton;
