import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export const StyledText = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
`;

export const Dot = styled.span`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 24px;
    background-color: #000000;
    display: inline-block;
    ${breakpoints.abovePhone} {
        margin: 0 31px;
    }
`;

export const StyledLink = styled.a`
    color: #000000;
    text-decoration: none;
`;
