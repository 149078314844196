import {useMutation, useQuery} from 'react-query';
import {ApiError} from 'next/dist/server/api-utils';

import {ApiEndpoints} from '~constants/api';
import useFetch from '~api/useFetch';
import {useJwtToken} from '~providers/AuthProvider';

type UnseenChangeType = {
    seenByUser: boolean;
    uuid: string;
    plotId: number;
    change: string;
    plotName: string;
    changedProperty: string;
    createdAt: string;
};

export default function useFetchUnseenChanges() {
    const {apiGet} = useFetch();
    const {token} = useJwtToken();

    return useQuery<UnseenChangeType[]>(
        ApiEndpoints.UnseenChanges,
        () =>
            apiGet(ApiEndpoints.UnseenChanges, {
                withJwtToken: true,
            }),
        {
            enabled: Boolean(token),
            retry: false,
        },
    );
}

type PutVariable = {
    uuids: string[];
};

export function usePutUnseenChanges() {
    const {apiPut} = useFetch();

    return useMutation<void, ApiError, PutVariable>(
        [ApiEndpoints.UnseenChanges],
        ({uuids}) =>
            apiPut(ApiEndpoints.UnseenChanges, {
                withJwtToken: true,
                data: {
                    uuids,
                },
            }),
    );
}
