import {Navigation} from '~components/atoms/Navigation';

import {BannerContainer} from './styles';

export const Banner = () => {
    return (
        <BannerContainer>
            <Navigation />
        </BannerContainer>
    );
};
