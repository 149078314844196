import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';
import {TextBig} from '~atoms/Text';
import Button from '~atoms/Button';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    margin-top: 16px;
`;
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${breakpoints.abovePhone} {
        max-width: 327px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-top: auto;
    width: 100%;
`;

export const StyledTextBig = styled(TextBig)`
    font-weight: 450;
    margin-left: 10px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

export const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    padding: 16px 24px;
    letter-spacing: 0.02em;
    height: 50px;

    svg {
        margin-right: 8px;
    }
`;
