import styled, {css} from 'styled-components';

import {Dropdown} from '~components/atoms/Dropdown/styles';
import {Text, textCss} from '~components/atoms/Text';
import breakpoints from '~constants/breakpoints';

type ContainerProps = {
    color: 'white' | 'black';
    absolute: boolean;
};

export const Container = styled.div<ContainerProps>`
    color: ${({color}) => color};
    align-items: center;
    ${({absolute}) =>
        absolute &&
        css`
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px 25px;
        `}

    z-index: 10;
    display: none;

    ${breakpoints.abovePhone} {
        display: flex;
    }
`;

export const SpeakerBtn = styled.button`
    background: none;
    appearance: none;
    margin: 2px 25px 0 0;
    padding: 0;
    color: inherit;
    border: none;
    cursor: pointer;
    transition: filter ease-in-out 300ms;

    &:hover {
        filter: invert(0.2);
    }

    svg {
        width: 16px;
        height: 16px;
    }
`;

export const WalletWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 40px;
    z-index: 5;

    &:hover {
        ${Dropdown} {
            display: block;
        }
    }
`;

export const WalletContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const WalletAddress = styled(Text)`
    margin: 0 14px 0 0;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
`;

export const DisconnectWalletBtn = styled.button`
    ${textCss};
    background: none;
    padding: 0;
    border: 0;
    appearance: none;
    color: var(--red);
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin: 8px 8px 0;
    cursor: pointer;

    & > *:first-child {
        margin-right: 5px;
        transform: translateY(-2px);
    }
`;
