import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export const BannerContainer = styled.div`
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
        #ffffff;
    color: black;
    height: 32px;
    min-height: 32px;
    align-items: center;
    justify-content: center;
    display: none;

    ${breakpoints.aboveTablet} {
        display: flex;
    }
`;
