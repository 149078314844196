import styled, {keyframes} from 'styled-components';

import {Text} from '~atoms/Text';

import {LoaderProps} from '.';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loaderSize = ({size}: LoaderProps) => size || 50;
const borderSize = (props: LoaderProps) => Math.max(2, loaderSize(props) / 10);

export const StyledLoader = styled.div<LoaderProps>`
    border: ${borderSize}px solid ${({bg}) => bg || '#fff'};
    border-top: ${borderSize}px solid ${({color}) => color || '#000'};
    border-radius: 50%;
    width: ${loaderSize}px;
    height: ${loaderSize}px;
    animation: ${animation} 0.7s linear infinite;
`;

export const LoadingInfo = styled(Text)<{color?: string}>`
    color: ${({color}) => color || '#000'};
    margin-top: 24px;
    z-index: inherit;
`;
