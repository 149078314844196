import {useEffect} from 'react';
import {useEthers} from '@usedapp/core';

import {showError} from '~utils/toast';
import getErrorMessage from '~utils/getErrorMessage';

const useErrors = () => {
    const {error} = useEthers();
    useEffect(() => {
        if (error && (error as any)?.error?.error?.code != -32000) {
            showError(getErrorMessage(error.message));
        }
    }, [error]);
};
export default useErrors;
