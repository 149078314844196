import {BSC, Goerli, Mainnet} from '@usedapp/core';

export default function getChainIdByName(name: string) {
    switch (name) {
        case 'mainnet':
            return Mainnet.chainId;
        case 'bsc':
            return BSC.chainId;
        case 'goerli':
            return Goerli.chainId;
        default:
            return BSC.chainId;
    }
}
