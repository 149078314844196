import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export const FooterContainer = styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: auto;
    padding-top: 120px;
    height: fit-content;
`;

export const FooterNavContainer = styled.div`
    width: fit-content;
    display: flex;
    margin-top: 65px;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;

    ${breakpoints.abovePhone} {
        margin-top: 80px;
    }
`;

export const FooterButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 73px;
    padding: 18px;

    ${breakpoints.abovePhone} {
        padding: 0 150px;
        margin-top: 90px;
    }
`;

export const TermsAndPrivacyContainer = styled.div`
    margin-top: 80px;
    padding: 17px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.02);
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.06em;
`;

export const StyledLink = styled.a`
    color: #000000;
    text-decoration: none;
`;

export const RightReserved = styled.span`
    display: inline;

    ${breakpoints.phone} {
        display: none;
    }
`;
