import {useEffect, useState} from 'react';
import {useEthers} from '@usedapp/core';
import {useRouter} from 'next/router';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import CloseIcon from '~assets/images/icons/ui/close.svg';
import MenuIcon from '~assets/images/icons/ui/menu.svg';
import Button from '~components/atoms/Button';
import Logo from '~assets/images/logo/logo.svg';
import {Navigation} from '~components/atoms/Navigation';
import ConnectWalletModal from '~molecules/ConnectWalletModal';
import {Routes} from '~constants/routes';
import useDisconnectWallet from '~atoms/ConnectWalletButtons/useDisconnectWallet';

import {ContentWrapper, InfoContainer, ToggleButton, TopBar, TopRow} from './styles';
import {UserSection} from './UserSection';

export type HamburgerMenuProps = {
    background?: boolean;
    color?: 'white' | 'black';
};

export const HamburgerMenu = ({background = true, color}: HamburgerMenuProps) => {
    const logOut = useDisconnectWallet();
    const t = useScopeTranslation('common');
    const router = useRouter();
    const [isOpen, setIsOpen] = useState<boolean | null>(null);
    const [connectWalletModalToggle, setConnectWalletModalToggle] = useState(false);
    const {account} = useEthers();

    useEffect(() => {
        if (account) setConnectWalletModalToggle(false);
    }, [account]);

    return (
        <>
            <TopBar background={background} isOpen={isOpen} color={color}>
                {background ? (
                    <Logo
                        onClick={() => router.push(Routes.Index)}
                        style={{pointerEvents: 'all'}}
                    />
                ) : (
                    <span />
                )}
                <ToggleButton onClick={() => setIsOpen((current) => !current)}>
                    {isOpen ? <CloseIcon /> : <MenuIcon />}
                </ToggleButton>
            </TopBar>
            <ContentWrapper isOpen={isOpen} color={color}>
                <TopRow>{!background && <Logo />}</TopRow>
                <InfoContainer>
                    <Navigation />
                </InfoContainer>
                {account ? (
                    <UserSection user={account} disconnectWallet={logOut} />
                ) : (
                    <Button onClick={() => setConnectWalletModalToggle(true)}>
                        {t('connectWallet')}
                    </Button>
                )}
            </ContentWrapper>
            <ConnectWalletModal
                isOpen={connectWalletModalToggle}
                close={() => setConnectWalletModalToggle(false)}
            />
        </>
    );
};
