import {useMutation} from 'react-query';

import useFetch from '~api/useFetch';
import env from '~utils/env';
import {IdmApiEndpoints} from '~constants/api';
import {AuthLoginData, AuthLoginResponse} from '~types/Auth';

export default function useFetchLogin() {
    const {apiPost} = useFetch();

    return useMutation<AuthLoginResponse, {}, AuthLoginData>((data) =>
        apiPost(IdmApiEndpoints.Login, {
            baseUrl: env('IDM_API'),
            data,
        }),
    );
}
