export enum Links {
    blank = '#',
    telegram = 'https://t.me/everdome',
    telegramNews = 'https://t.me/everdome_io_news',
    twitter = 'https://twitter.com/Everdome_io',
    linkedIn = 'https://www.linkedin.com/company/everdome-io/',
    insta = 'https://www.instagram.com/everdome.ig/',
    yt = 'https://www.youtube.com/c/Metahero',
    terms = 'https://everdome.io/terms',
    privacy = 'https://everdome.io/privacy-policy',
    everdome = 'https://everdome.io/',
    contact = 'https://everdome.io/contact-us',
    discord = 'https://discord.gg/everdome',
}
