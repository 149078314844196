import styled, {css} from 'styled-components';

import {textCss, textSmallCss} from '~atoms/Text';

export const Icon = styled.div`
    transition: ease-in-out 0.15s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9.5px;
`;

export type StylesButtonProps = {
    variant?: 'primary' | 'stroked' | 'transparent' | 'blurred';
    $loading?: boolean;
    textSize?: 'small' | 'regular';
    fullWidth?: boolean;
};

const variantStyle = (variant: StylesButtonProps['variant'] = 'primary') => {
    switch (variant) {
        case 'stroked':
            return css`
                border: 1px solid #000;
                background-color: #fff;
                color: #000;

                &:hover:not([disabled]) {
                    background-color: #000;
                    color: #fff;
                    border: 1px solid #000;
                }

                &:disabled {
                    filter: opacity(0.15) !important;
                }
            `;
        case 'transparent':
            return css`
                border: 1px solid #fff;
                background-color: transparent;
                color: #fff;

                &:hover:not([disabled]) {
                    background-color: #fff;
                    color: #000;
                    border: 1px solid #fff;

                    ${Icon} {
                        filter: invert(1);
                    }
                }
            `;
        case 'blurred':
            return css`
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(8px);
                color: #fff;

                &:hover:not([disabled]) {
                    background: rgba(255, 255, 255, 0.4);
                    backdrop-filter: blur(16px);
                }
            `;
        case 'primary':
        default:
            return css`
                border: 1px solid #fff;
                background-color: #000;
                color: #fff;

                &:hover:not([disabled]) {
                    background-color: #fff;
                    color: #000;
                    border: 1px solid #000;
                }
            `;
    }
};

const loadingStyle = (loading: StylesButtonProps['$loading']) => {
    if (loading) {
        return css`
            color: transparent;
        `;
    }
    return undefined;
};

export const StylesButton = styled.button<StylesButtonProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 500;
    text-align: center;
    margin: 0;
    padding: 6px 24px;
    transition: ease-in-out 0.15s;
    cursor: pointer;
    font-family: inherit;
    border-radius: 36px;
    height: 40px;
    user-select: none;

    &:disabled {
        cursor: default;
        filter: opacity(0.7) !important;
    }

    &:active {
        filter: invert(0.3);
    }

    ${({textSize}) => (textSize ? textCss : textSmallCss)}
    ${({variant}) => variantStyle(variant)}
  ${({$loading}) => loadingStyle($loading)}
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;
