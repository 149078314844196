import {useCallback, useRef} from 'react';
import {useEthers} from '@usedapp/core';
import {toUtf8Bytes} from 'ethers/lib/utils';

import useFetchNonce from '~api/useFetchNonce';
import useFetchLogin from '~api/useFetchLogin';
import {useJwtToken} from '~providers/AuthProvider';
import useFetchRefreshToken from '~api/useFetchRefreshToken';

export default function useLogin() {
    const fetchRefreshToken = useFetchRefreshToken();
    const active = useRef(false);
    const {token, setToken, removeToken} = useJwtToken();
    //New version of Ethers has problems with type library besides it is library?: JsonRpcProvider
    const {account, library}: any = useEthers();
    const nonce = useFetchNonce(account);
    const login = useFetchLogin();

    const renewRefreshToken = useCallback(async () => {
        if (!account || !library || active.current) {
            return;
        }
        active.current = true;
        const {data: nonceData} = await nonce.refetch();
        if (!nonceData) {
            active.current = false;
            return;
        }
        const signer = library.getSigner();
        const signature = await signer.signMessage(toUtf8Bytes(nonceData.message));
        const token = await login.mutateAsync({signature, walletAddress: account});
        setToken(token);
        active.current = false;
    }, [setToken, account, library, nonce, login, active]);

    const renewAccessToken = useCallback(async () => {
        if (token) {
            try {
                const newToken = await fetchRefreshToken(token.refreshToken.token);
                setToken(newToken);
            } catch (e) {
                removeToken();
            }
        }
    }, [fetchRefreshToken, token, setToken, removeToken]);

    return {renewAccessToken, renewRefreshToken};
}
