import React from 'react';

import Logo from '~assets/images/logo/logo.svg';

const RefLogo = React.forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div ref={ref} {...props}>
            <Logo />
        </div>
    );
});

RefLogo.displayName = 'RefLogo';

export default RefLogo;
