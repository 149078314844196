import Link from 'next/link';

import {Routes} from '~constants/routes';
import {DesktopWalletMenu} from '~molecules/DesktopWalletMenu';
import RefLogo from '~atoms/RefLogo';

import {DesktopTopBarContainer, WidthLimiter} from './styles';

export const DesktopTopBar = () => {
    return (
        <DesktopTopBarContainer>
            <WidthLimiter>
                <Link href={Routes.Index} passHref>
                    <RefLogo />
                </Link>

                <DesktopWalletMenu color="black" absolute={false} />
            </WidthLimiter>
        </DesktopTopBarContainer>
    );
};
