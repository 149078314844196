import React from 'react';

import {mintChainId} from '~constants/chainId';
import ETHIcon from '~assets/images/icons/ui/eth.svg';
import BSCIcon from '~assets/images/icons/ui/BNBChain.svg';

const NetworkIcon = ({chainId}: {chainId: number}) => {
    return chainId === mintChainId ? <ETHIcon /> : <BSCIcon />;
};
export default NetworkIcon;
