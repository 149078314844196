import {Dot, StyledLink, StyledText} from './styles';

type FooterNavItemProps = {
    text: string;
    lastItem?: boolean;
    href: string;
};

const FooterNavItem = ({text, lastItem, href}: FooterNavItemProps) => {
    return (
        <>
            <StyledText>
                <StyledLink href={href} target="_blank" rel="noopener">
                    {text}
                </StyledLink>
            </StyledText>
            {!lastItem && <Dot />}
        </>
    );
};

export default FooterNavItem;
