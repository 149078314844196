const maxWidth = (value: number) => `@media only screen and (max-width: ${value}px)`;
const minWidth = (value: number) =>
    `@media only screen and (min-width: ${value + 1}px)`;

const breakpoints = {
    tablet: maxWidth(1088),
    phone: maxWidth(768),
    phoneS: maxWidth(576),
    abovePhoneS: minWidth(576),
    abovePhone: minWidth(768),
    aboveTablet: minWidth(1088),
    aboveDesktop: minWidth(1400),
    aboveBigDesktop: minWidth(1700),
};

export default breakpoints;
