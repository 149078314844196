import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

export const DesktopTopBarContainer = styled.div`
    width: 100%;
    position: relative;

    ${breakpoints.phone} {
        display: none;
    }
`;

export const WidthLimiter = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 8px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
`;
