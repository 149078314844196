import React, {useEffect, useState} from 'react';
import {useEthers} from '@usedapp/core';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import {Modal} from '~components/atoms/Modal';
import {Portals} from '~constants/portals';
import {Text} from '~atoms/Text';
import useDisconnectWallet from '~atoms/ConnectWalletButtons/useDisconnectWallet';
import {mintChainId} from '~constants/chainId';
import NetworkIcon from '~atoms/NetworkIcon ';

import {
    Buttons,
    Container,
    Content,
    Row,
    StyledButton,
    StyledTextBig,
} from './styles';

type ConnectWalletModalProps = {
    chainId: number;
};

export const SwitchNetworkModal = ({chainId}: ConnectWalletModalProps) => {
    const t = useScopeTranslation('common', 'switchNetwork');
    const {switchNetwork} = useEthers();
    const {chainId: currentChainId, account} = useEthers();
    const [isSwitchNetworkModalOpen, setIsSwitchNetworkModalOpen] = useState(false);

    useEffect(() => {
        setIsSwitchNetworkModalOpen(Boolean(account) && currentChainId !== chainId);
    }, [chainId, account, currentChainId]);

    const handleClose = useDisconnectWallet();

    return (
        <Modal
            rootId={Portals.SWITCH_NETWORK}
            width={416}
            height={287}
            title={t('overtitle')}
            isOpen={isSwitchNetworkModalOpen}
        >
            <Container>
                <Content>
                    <Row>
                        <NetworkIcon chainId={chainId} />
                        <StyledTextBig>{t('title')}</StyledTextBig>
                    </Row>
                    <Text>
                        {t(
                            chainId === mintChainId
                                ? 'descriptionETH'
                                : 'descriptionBSC',
                        )}
                    </Text>
                </Content>
                <Buttons>
                    <StyledButton onClick={() => switchNetwork(chainId)}>
                        {t('btnLabel')}
                    </StyledButton>
                    <StyledButton variant={'stroked'} onClick={handleClose}>
                        {t('btnClose')}
                    </StyledButton>
                </Buttons>
            </Container>
        </Modal>
    );
};
