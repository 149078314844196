import React from 'react';
import {useRouter} from 'next/router';
import {useScopeTranslation} from '@uigstudio/uig-nextjs-helper';

import ConnectWalletModal from '~molecules/ConnectWalletModal';
import {Loader} from '~atoms/Loader';
import {FCC} from '~types/global';
import useSecuredPage from '~molecules/SecuredPage/useSecuredPage';
import {DisconnectWalletBtn} from '~molecules/HamburgerMenu/UserSection/styles';
import useDisconnectWallet from '~atoms/ConnectWalletButtons/useDisconnectWallet';
import {Routes} from '~constants/routes';

import {LoaderContainer} from './styles';

type SecuredPageProps = {
    isSecure?: boolean;
    forAdmin?: boolean;
};

const SecuredPage: FCC<SecuredPageProps> = ({
    children,
    isSecure = false,
    forAdmin = false,
}) => {
    const disconnect = useDisconnectWallet();
    const t = useScopeTranslation('common');
    const router = useRouter();

    const {account, loading, hasAccess, isAdmin} = useSecuredPage(
        isSecure,
        forAdmin,
    );

    if (loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }
    if (hasAccess) {
        return <>{children}</>;
    }

    if (forAdmin && account && !isAdmin) {
        return (
            <LoaderContainer>
                <span>{t('notAdmin')}</span>
                <DisconnectWalletBtn onClick={disconnect}>
                    {t('disconnectWallet')}
                </DisconnectWalletBtn>
            </LoaderContainer>
        );
    }

    return (
        <ConnectWalletModal isOpen={true} close={() => router.push(Routes.Index)} />
    );
};

export default SecuredPage;
