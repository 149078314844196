import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';

type AvatarProps = {
    size: number;
    mobileSize?: number;
};

export const Avatar = styled.div<AvatarProps>`
    width: ${({mobileSize, size}) => mobileSize || size}px;
    min-width: ${({mobileSize, size}) => mobileSize || size}px;
    height: ${({mobileSize, size}) => mobileSize || size}px;
    background: linear-gradient(0deg, #53271a 0%, #ff6336 100%);
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.12);
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;

    ${breakpoints.abovePhone} {
        min-width: ${({size}) => size}px;
        width: ${({size}) => size}px;
        height: ${({size}) => size}px;
    }
`;
