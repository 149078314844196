import {useCallback} from 'react';
import {resolveUrl} from '@uigstudio/uig-nextjs-helper';

import {IdmApiEndpoints} from '~constants/api';
import env from '~utils/env';
import {fetchBody, fetchHeaders, resolveResponse} from '~utils/fetch';
import {AuthLoginResponse} from '~types/Auth';

export default function useFetchRefreshToken() {
    return useCallback(
        (refreshToken: string) =>
            resolveResponse<AuthLoginResponse>(
                fetch(resolveUrl(env('IDM_API'), IdmApiEndpoints.RefreshToken), {
                    method: 'POST',
                    redirect: 'follow',
                    ...fetchBody({data: {refreshToken}}),
                    ...fetchHeaders({withContentType: true}),
                }),
            ),
        [],
    );
}
