import styled, {css} from 'styled-components';

import breakpoints from '~constants/breakpoints';
import {textCss, textSmallCss} from '~atoms/Text';

export const RoundInfoContainer = styled.div`
    display: grid;
    gap: 16px 24px;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    width: 100%;
    ${textCss};

    ${breakpoints.aboveTablet} {
        ${textSmallCss};
        grid-template-rows: auto;
        grid-template-columns: unset;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        justify-content: center;
    }

    ${breakpoints.phone} {
        strong {
            line-height: 3px;
        }
    }
`;

export const HowItWorksLink = styled.a`
    display: flex;
    align-items: center;

    svg {
        margin-left: 8px;
        transform: scale(-1, -1) translateY(1px);
    }
`;
type LinkTextProps = {
    disabled?: boolean;
};

export const LinkText = styled.a<LinkTextProps>`
    display: inline-flex;
    align-items: center;

    ${({disabled}) =>
        disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `}
    svg {
        width: 16px;
        margin-right: 8px;
    }
`;
