import styled, {css} from 'styled-components';

export const H1 = styled.h1`
    font-weight: 400;
    font-size: 64px;
    line-height: 73px;
    letter-spacing: 0.04em;
`;

export const H2 = styled.h2`
    font-weight: 400;
    font-size: 48px;
    line-height: 55px;
    letter-spacing: 0.04em;
`;

export const H3 = styled.h3`
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.04em;
`;

export const H4 = styled.h4`
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.04em;
`;

export const H5 = styled.h5`
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    letter-spacing: 0.04em;
    margin: 0;
`;

export const H6 = styled.h6`
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.04em;
`;

export const TextBig = styled.span`
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
`;

export const textCss = css`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
`;

export const Text = styled.span`
    ${textCss};
`;

export const textSmallCss = css`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.06em;
`;

export const TextSmall = styled.span`
    ${textSmallCss}
`;

export const Label = styled(TextSmall)`
    opacity: 0.5;
`;

export const TextBold = styled.span`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
`;

export const TextSmallBold = styled.span`
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
`;
