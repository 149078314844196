import styled, {css} from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    position: relative;
    overflow-x: hidden;
`;

export type ContentProps = {
    hideScrollbar?: boolean;
    withMapBackground?: boolean;
};

export const Content = styled.main.attrs({
    style: {
        backgroundColor: '#aa3d1a',
        backgroundImage: "url('/assets/images/bg/map-bg.jpeg')",
    },
})<ContentProps>`
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${({hideScrollbar}) =>
        hideScrollbar &&
        css`
            &::-webkit-scrollbar {
                width: 0 !important;
            }
        `}
    ${({withMapBackground}) =>
        !withMapBackground &&
        css`
            background: none !important;
        `}
`;
